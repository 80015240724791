import { MENU_ROUTES } from '../../Router/constants';

export const NAVIGATION_MENU: MENU_ROUTES[] = [
  MENU_ROUTES.Home,
  MENU_ROUTES.Projects,
  MENU_ROUTES.News,
  MENU_ROUTES.About,
  MENU_ROUTES.Cooperation,
  MENU_ROUTES.Contact,
];

export const NAVIGATION_FOOTER_MENU: MENU_ROUTES[] = [
  MENU_ROUTES.Home,
  MENU_ROUTES.Projects,
  MENU_ROUTES.NewsAndEvents,
  MENU_ROUTES.About,
  MENU_ROUTES.Cooperation,
  MENU_ROUTES.Contact,
  MENU_ROUTES.UserAgreement,
];
