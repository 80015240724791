import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import AboutPage from '../pages/AboutPage';
import HomePage from '../pages/HomePage';
import InProgressPage from '../pages/InProgressPage';
import NotFoundPage from '../pages/NotFoundPage';
import Projects from '../pages/Projects';
import SupportPage from '../pages/SupportPage';
import { ROUTES_PATH } from './constants';

const AppRoutes: React.FC = (): JSX.Element => (
  <Router>
    <Routes>
      <Route path={ROUTES_PATH.home} element={<HomePage />} />
      <Route path={ROUTES_PATH.projects} element={<Projects isBlue />} />
      <Route path={ROUTES_PATH.about} element={<AboutPage isBlue />} />
      <Route path={ROUTES_PATH.support} element={<SupportPage isBlue />} />
      <Route path={ROUTES_PATH.news} element={<InProgressPage isBlue />} />
      <Route
        path={ROUTES_PATH.cooperation}
        element={<InProgressPage isBlue />}
      />
      <Route path={ROUTES_PATH.contact} element={<InProgressPage isBlue />} />
      <Route
        path={ROUTES_PATH.userAgreement}
        element={<InProgressPage isBlue />}
      />
      <Route path="*" element={<NotFoundPage isBlue />} />
    </Routes>
  </Router>
);

export default AppRoutes;
