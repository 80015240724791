import React from 'react';
import Marquee from 'react-fast-marquee';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Container,
  Grid,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { LINKS } from '../../assets/constants';
import { ButtonComponent, ButtonSize, ButtonVariant } from '../ButtonComponent';
import { LanguageToggle } from '../LanguageToggle';
import { Logo } from '../Logo';
import { NAVIGATION_FOOTER_MENU } from '../Navigation/constants';
import { NavLink } from '../Navigation/NavLink';
import { SocialNavbar } from '../SocialNavbar';

interface FooterProps {}

export const Footer: React.FC<FooterProps> = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const isSmallTablet = useMediaQuery(theme.breakpoints.up(540));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const isBigDesktop = useMediaQuery(theme.breakpoints.up(1280));

  const typographyStyles = { fontSize: 18 };

  const renderLine = () => (
    <Box style={{ borderBottom: `1px solid ${theme.palette.lightGray}` }} />
  );

  const renderLogo = () => (
    <Box style={{ minWidth: '164px', width: '164px' }}>
      <Logo isWhite />
    </Box>
  );

  const renderMobile = () => {
    if (isSmallTablet) return null;

    return (
      <>
        <Grid item xs={6}>
          {renderLogo()}
        </Grid>
        <Grid item xs={6} sx={{ mt: 1, textAlign: 'end' }}>
          <LanguageToggle isDark />
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <SocialNavbar
            isWhite
            instagramLink={LINKS.instagramLink}
            facebookLink={LINKS.facebookLink}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          {renderLine()}
        </Grid>
        <Grid item xs={12} sx={{ ml: -1, mt: 3 }}>
          {NAVIGATION_FOOTER_MENU.map((path) => (
            <MenuItem key={path} sx={{ mb: 2 }}>
              <NavLink isWhite paragraphStyles={typographyStyles} path={path} />
            </MenuItem>
          ))}
        </Grid>
        <Grid item xs={12}>
          {renderLine()}
        </Grid>
        <Grid item xs={12} sx={{ mt: 4 }}>
          <ButtonComponent
            size={ButtonSize.small}
            textButton={t('navigation.support')}
            variant={ButtonVariant.white}
            withRightArrow
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography paragraph style={typographyStyles}>
            {t('navigation.phone')}
          </Typography>
          <Typography paragraph sx={{ mt: 4 }} style={typographyStyles}>
            {t('navigation.email')}
          </Typography>
          <Typography
            paragraph
            sx={{ mt: 4 }}
            style={{ color: theme.palette.gray }}
            variant="body2"
          >
            {t('navigation.copy')}
          </Typography>
        </Grid>
      </>
    );
  };

  const renderSmallTablet = () => {
    if (!isSmallTablet) return null;
    if (isBigDesktop) return null;

    return (
      <>
        <Grid item xs={3}>
          {renderLogo()}
        </Grid>
        <Grid item xs={6} sx={{ mt: 1, textAlign: 'center' }}>
          <LanguageToggle isDark />
        </Grid>
        <Grid item xs={3} sx={{ mt: 3 }}>
          <SocialNavbar
            isWhite
            instagramLink={LINKS.instagramLink}
            facebookLink={LINKS.facebookLink}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          {renderLine()}
        </Grid>
        <Grid item xs={5} md={3.5} sx={{ ml: -1, mt: 3 }}>
          {NAVIGATION_FOOTER_MENU.slice(0, 4).map((path) => (
            <MenuItem key={path} sx={{ mb: 2 }}>
              <NavLink isWhite paragraphStyles={typographyStyles} path={path} />
            </MenuItem>
          ))}
        </Grid>
        <Grid item xs={7} md={3.5} sx={{ ml: 0, mt: 3 }}>
          {NAVIGATION_FOOTER_MENU.slice(4).map((path) => (
            <MenuItem key={path} sx={{ mb: 2 }}>
              <NavLink isWhite paragraphStyles={typographyStyles} path={path} />
            </MenuItem>
          ))}
        </Grid>
        <Grid item xs={12} hidden={isDesktop}>
          {renderLine()}
        </Grid>
        <Grid item xs={12} md={5} sx={{ mt: 4 }}>
          <ButtonComponent
            size={ButtonSize.small}
            textButton={t('navigation.support')}
            variant={ButtonVariant.white}
            withRightArrow
          />
          <Typography paragraph sx={{ mt: 4 }} style={typographyStyles}>
            {t('navigation.phone')}
          </Typography>
          <Typography paragraph sx={{ mt: 4 }} style={typographyStyles}>
            {t('navigation.email')}
          </Typography>
          <Typography
            paragraph
            sx={{ color: theme.palette.gray, mt: 4 }}
            style={{ fontSize: 14 }}
          >
            {t('navigation.copy')}
          </Typography>
        </Grid>
      </>
    );
  };

  const renderBigDesktop = () => {
    if (!isBigDesktop) return null;

    return (
      <>
        <Grid item xs={2.5} lg={3} xl={2.5}>
          {renderLogo()}
          <Box sx={{ mt: 6 }}>
            <LanguageToggle isDark />
          </Box>
          <Box sx={{ mt: 6 }}>
            <SocialNavbar
              isWhite
              instagramLink={LINKS.instagramLink}
              facebookLink={LINKS.facebookLink}
            />
          </Box>
        </Grid>
        <Grid item xs={2.5} lg={2} xl={2.5} sx={{ ml: -1, mt: 3 }}>
          {NAVIGATION_FOOTER_MENU.slice(0, 4).map((path) => (
            <MenuItem key={path} sx={{ mb: 2 }}>
              <NavLink isWhite paragraphStyles={typographyStyles} path={path} />
            </MenuItem>
          ))}
        </Grid>
        <Grid item xs={2.5} lg={3} sx={{ ml: 0, mt: 3 }}>
          {NAVIGATION_FOOTER_MENU.slice(4).map((path) => (
            <MenuItem key={path} sx={{ mb: 2 }}>
              <NavLink isWhite paragraphStyles={typographyStyles} path={path} />
            </MenuItem>
          ))}
        </Grid>
        <Grid item xs={12} hidden={isDesktop}>
          {renderLine()}
        </Grid>
        <Grid item xs={4} sx={{ mt: 4 }}>
          <ButtonComponent
            size={ButtonSize.small}
            textButton={t('navigation.support')}
            variant={ButtonVariant.white}
            withRightArrow
          />
          <Typography paragraph sx={{ mt: 4 }} style={typographyStyles}>
            {t('navigation.phone')}
          </Typography>
          <Typography paragraph sx={{ mt: 4 }} style={typographyStyles}>
            {t('navigation.email')}
          </Typography>
          <Typography
            paragraph
            sx={{ color: theme.palette.gray, mt: 4 }}
            style={{ fontSize: 14 }}
          >
            {t('navigation.copy')}
          </Typography>
        </Grid>
      </>
    );
  };

  return (
    <footer
      style={{
        backgroundColor: theme.palette.black,
        color: theme.palette.white,
      }}
    >
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Grid
          container
          justifyContent={isLarge ? 'start' : 'space-between'}
          spacing={2}
        >
          {renderMobile()}
          {renderSmallTablet()}
          {renderBigDesktop()}
        </Grid>
      </Container>
      <Marquee>
        <Typography
          paragraph
          sx={{ color: theme.palette.darkYellow }}
          style={{ fontSize: 14 }}
        >
          {t('navigation.textRibbon')}
        </Typography>
      </Marquee>
    </footer>
  );
};
